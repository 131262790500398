.brand-wrapper{
    display: flex;
    justify-content: center;
}

.brand-box{
    max-width: 275px;
    margin-bottom: 3rem; 
}
.brand-box img{
    width: 100%;
}

@media screen and (max-width: 576px) {
    .brand-box{
        max-width: 200px;
        margin-bottom: 2rem;
    }
}