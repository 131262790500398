.share-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3rem;
}

.share-boxes{
    margin-top: .75rem;
}

.twitter-share-btn{
    display: flex;
    align-items: center;
    background-color: rgb(0, 172, 237) !important;
    color:  #ffffff !important;
    padding: 0.5rem 2rem !important;
    border-radius: 5px;
    transition: all .3s;
    text-decoration: none;
}

.twitter-share-btn:hover{
    background-color: rgb(0, 158, 215) !important;
}

.twitter-share-btn svg{
    width: 20px;
    margin-left: .5rem;
}