:root {
    --wheel-font: inherit;
    --wheel-size: 500px;
    --wheel-slice-spacing: 50px;
    --wheel-border-size: 5px;
    --wheel-color: #00c2c7;
    --neutral-color: white;
    --PI: 3.14159265358979;
    --nb-item: 0;
    --item-nb: 0;
    --selected-item: 0;
    --nb-turn: 7;
    --spinning-duration: 4s;
    --reset-duration: 0.25s;
  }
   
  .wheel-container {
    display: block;
    position: relative;
    box-sizing: content-box;
    width: calc(var(--wheel-size) + 2 * var(--wheel-border-size));
    height: calc(var(--wheel-size) + 2 * var(--wheel-border-size));
    padding: 8px;
    margin: auto;
    background-color: var(--neutral-color);
    border: solid var(--wheel-color) 5px;
    border-radius: 50%;
    user-select: none;
    display: flex;
  }
   
  .wheel-container::before,
  .wheel-container::after {
    content: '';
    display: block;
    position: absolute;
    height: 0;
    width: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    border: solid transparent 20px;
    border-left-width: 0;
  }
 
  .wheel {
    display: block;
    position: relative;
    box-sizing: content-box;
    margin: auto;
    width: var(--wheel-size);
    height: var(--wheel-size);
    overflow: hidden;
    border-radius: 50%; 
    background-color: var(--wheel-color);
    transition: transform var(--reset-duration);
    transform: rotate(0deg);
    /* cursor: pointer; */
    box-shadow: inset 0 0 0 12px hsl(0deg 0% 0% / 0.1);
  }
  
  .wheel.spinning {
    transition: transform var(--spinning-duration) cubic-bezier(0.1, -0.01, 0, 1);
    transform: rotate(calc(var(--nb-turn) * 360deg + (-360deg * var(--selected-item) / var(--nb-item, 1))));
  }
   
  .wheel::after {
    display: block;
    position: absolute;
    content: '';
    background-color: var(--neutral-color);
    width: 25px;
    height: 25px;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }
   
  .wheel-item {
    display: block;
    position: absolute;
    box-sizing: border-box; 
    top: 50%;
    left: 50%;
    width: 50%;
    transform-origin: center left;
    transform: translateY(-50%) rotate(calc(var(--item-nb) * (360deg / var(--nb-item, 1))));
    font-weight: 600;
    /* texte */
    color: var(--neutral-color);
    text-align: right;
    text-align: center;
    padding: 0 25px 0 50px;
    font-family: var(--wheel-font);
    display: flex;
    justify-content: center;
  }

  .wheel-item.with-icon {

  }
  
  .wheel-item-iconbox {
    width: 90px;
    height: 90px;
    min-height:50px;
    background: white;
    min-width: 50px;
    border-radius: 50%;
    padding:10px;
    display:flex;
    justify-content:center;
    align-items:center;
    border: 5px solid #e9e7e7;
}

  .wheel-item-iconbox img{
    width:100%;
    height: 100%;
    max-width:55px;
    max-height:55px;
    object-fit: contain;  
  }
  
  .spin-btn {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    border: none;
    background: var(--wheel-color);
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    z-index: 99;
    border: 5px solid white;
  }

  .spin-btn:disabled{
    cursor: not-allowed;
  }
 
  .ticker {
    position: absolute;
    top: 50%;
    right: 0; 
    width: 58px;
    height: 30px; 
    background: linear-gradient(hsl(0 3% 0%) 0 50%, hsl(0 3% 20%) 50% 100%);
    z-index: 1;
    clip-path: polygon(20% 0, 100% 50%, 20% 100%, 0% 50%);
    transform: translateY(-50%) scaleX(-1);
    transform-origin: center left;
    z-index: 9999;
    right: -72px;
  }
  
  .ticker.animate {
    animation: tick 100ms cubic-bezier(0.1, -0.01, 0, 1) infinite;
  }
  
  .spin-result{
    text-align: center;
    margin-top: 1.5rem;
  }
  .spin-result h2{
    font-weight: 600;
    font-size: 1.5rem;
  }

  .spin-result h2 span{
    color:#196BDE ;
  }

  .loss-heading{
    color: red;
  }

  @keyframes tick {
    40% {
      transform: translateY(-50%) scaleX(-1) rotate(-15deg);
    }
  }
  
  @media screen and (max-width: 768px) {
    :root { 
        --wheel-size: 450px;
    }
  }
  
  @media screen and (max-width: 576px) {
    :root { 
        --wheel-size: 300px;
    }
    .wheel-item {
        font-size: 12px;
    }
    .ticker{
        width: 40px;
        height: 22px;
        right: -52px;
    }
    .wheel-item-iconbox {
      width: 70px;
      height: 70px;
    }
  }
  
  @media screen and (max-width: 416px) {
    :root { 
      --wheel-size: 260px;
    }
    .wheel-container{
      padding: 2px;
    }
    .wheel-item {
      font-size: 10px;
    }
    .ticker {
      width: 35px;
      height: 18px;
      right: -45px;
    }
    .spin-btn {
      width: 60px;
      height: 60px;
    }

    .wheel-item-iconbox {
      width: 50px;
      height: 50px; 
  }
  
    .wheel-item-iconbox img{ 
        max-width:45px;
        max-height:45px; 
    }

  }
  
 