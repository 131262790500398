/* Import Google font - Poppins */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

body {
  width: 100%;
  padding: 1.5rem 1rem;
  background: #edf4f9;
}

.container {
  position: relative;
  width: 800px;
  background: #fff;
  padding: 48px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  min-height: calc(100vh - 3rem);
  margin: auto;
}

.container header {
  font-size: 1.5rem;
  color: #333;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
}

.text-uppercase {
  text-transform: uppercase;
}

.sub-heading {
  font-size: 26px;
  color: rgb(45, 49, 51);
  font-weight: 500;
  line-height: 1;
}

.para-one {
  color: rgb(96, 109, 120);
  font-size: 14px;
  max-width: 500px;
  margin-top: .5rem;
}

.breadcrumb {
  display: flex;
  gap: .5rem;
  padding: 0;
  list-style-type: none;
  margin-top: 1rem;
}

.breadcrumb li {
  padding: 0;
  margin: 0;
  font-size: 17px;
}

.note {
  line-height: 1.2;
  margin-top: 1.5rem;
}

small {
  font-size: 12px;
  color: rgb(96, 109, 120);
}

.container.thank-you-container{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #196BDE;
  font-size: 3rem;
}

/*Responsive*/
@media screen and (max-width: 991px) {
  .container {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .container {
    padding: 40px;
  }
}

@media screen and (max-width: 576px) {
  .container {
    padding: 32px 16px;
  }

  .sub-heading {
    font-size: 20px;
  }
}

@media screen and (max-width: 416px) {
  .container {
    padding: 32px 16px;
  }
}