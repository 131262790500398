 .container .form {
   margin-top: 30px;
 }

 .form .input-box {
   width: 100%;
   margin-top: 20px;
 }

 .input-box label {
   color: #403f42;
   font-size: 16px;
   font-weight: 600;
 }

 .form :where(.input-box input, .select-box) {
   position: relative;
   height: 50px;
   width: 100%;
   outline: none;
   font-size: 1rem;
   color: #707070;
   margin-top: 8px;
   border: 1px solid #333333;
   border-radius: 3px;
   padding: 0 15px;
 }

 .input-box input:focus {
   box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
 }

 .input-btn {
   width: 100%;
   display: flex;
   justify-content: center;
 }

 .form button {
   height: 45px;
   width: 120px;
   color: #fff;
   font-size: 1rem;
   font-weight: 400;
   margin-top: 30px;
   border: none;
   cursor: pointer;
   transition: all 0.2s ease;
   background: #3575d3;
   border-radius: 4px;
 }

 .required {
   color: #d32f2f;
 }

 .form input:focus {
   outline: 1px solid black;
   border: 1px solid black;
 }

 .characterCount {
   display: flex;
   justify-content: space-between;
   color: #717a80;
   font-size: 14px;
 }

 .error-msg {
   color: red;
 }

 .form .radio-check-box {
   margin-top: 20px;
 }

 .radio-check-box h3 {
   color: #403f42;
   font-size: 16px;
   font-weight: 600;
   margin-bottom: 8px;
 }

 .form .option {
   column-gap: 5px;
   display: flex;
   margin-bottom: 5px;
 }

 .form .radio-check-box .radio-check-box-option {
   display: flex;
   flex-direction: column;
 }

 .option input[type="radio"],input[type="checkbox"] {
   accent-color: #3575d3;
   outline: none !important;
   box-shadow: none !important;
   width: 20px;
   height: 20px;
 }

 .form :where(.option input, .option label) {
   cursor: pointer;
 }

 .option label {
   margin-left: 5px;
   color: #403F42;
   font-size: 14px;
 }